import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueSweetalert2 from 'vue-sweetalert2';
import VueImg from 'v-img';
import 'sweetalert2/dist/sweetalert2.min.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import en from './lang/en'
import th from './lang/th'
import FullFreeBuildEditor from '@blowstack/ckeditor5-full-free-build'
// import CKEditor from '@ckeditor/ckeditor5-vue2'

const instance = axios.create({
  // baseURL: 'https://apisg.agc-flatglass.co.th'
  baseURL: 'https://iservice.agc-flatglass.co.th'
   //baseURL: 'http://localhost:3000'
})
const vueImgConfig = {
  // Use `alt` attribute as gallery slide title
  altAsTitle: false,
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
  // Event listener to open gallery will be applied to <img> element
  openOn: 'click',
  // Show thumbnails for all groups with more than 1 image
  thumbnails: false,
}
Vue.use(VueI18n)
Vue.prototype.$baseFront = "https://file.agc-flatglass.co.th"
Vue.prototype.$lang = localStorage.getItem('locale') || 'en'
Vue.prototype.$axios = instance
Vue.use(VueImg, vueImgConfig);
Vue.use(VueSweetalert2);
Vue.use(VueRouter);
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);
// Vue.use(CKEditor);

import * as VueGoogleMaps from 'vue2-google-maps'
 
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB3Qq8bMAdBAHvI1_Df7HE0YggDt_OJBvY',
    //key :'AIzaSyD-rmbfyc1ntqTnGtESZugPJi32ZeMCnaI',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
})

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', component: () => import("@/components/home/Home"), meta: { title: 'AGC Home' } },
    { path: '/contact-us', component: () => import("@/components/contactus/Contact") },
    { path: '/SalesChannel', component: () => import("@/components/sale/Sale") },
    { path: '/solar', component: () => import("@/components/solar/Solar") },
    { path: '/product', component: () => import("@/components/product/Product") },
    { path: '/product-detail', component: () => import("@/components/product_detail/ProductDetail") },
    { path: '/product-detail/:id', component: () => import("@/components/product_detail/ProductDetail") },
    { path: '/product-content/:id', component: () => import("@/components/product_content/ProductContent") },
    { path: '/footprint', component: () => import("@/components/footprint/Footprint") },
    { path: '/footprint-detail/:id', component: () => import("@/components/footprint_detail/FootprintDetail") },
    // { path: '/career', component: () => import("@/components/career/Career") },
    { path: '/sustainability', component: () => import("@/components/sustainability/Sustainability") },
    { path: '/subtainability-content/:id', component: () => import("@/components/sustainability_content/SustainabilityContent") },
    { path: '/about-us', component: () => import("@/components/aboutUs/AboutUs") },
    { path: '/news', component: () => import("@/components/news/News") },
    { path: '/content/:id', component: () => import("@/components/content/Content") },
    // { path: '/news-content/:id', component: () => import("@/components/news_content/NewsContent") },
    // { path: '/news-detail', component: () => import("@/components/news_detail/NewsDetail") },
    { path: '/compare', component: () => import("@/components/compare/Compare") },
    { path: '/technical-support', component: () => import("@/components/technical_support/Technical") },
    { path: '/findyourglass', component: () => import("@/components/find_your_glass/FindYourGlass") },
    { path: '/policy', component: () => import("@/components/policy/Policy") },
    { path: '/map', component: () => import("@/components/map/Map") },
    { path: '/search', component: () => import("@/components/search/search") },
    {
      path: '/career',
      redirect: '/', // Redirect all unknown routes to a NotFound component
    },
    {
      path: '*',
      redirect: '/', // Redirect all unknown routes to a NotFound component
    },
  ]
});

const messages = {
  en: en,
  th: th
}
const locale = localStorage.getItem('locale') || 'en'
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale, // set locale
  messages
})


Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  el: "#app",
  created () {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
