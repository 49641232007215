export default {
  name: "modal",
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
      location.reload();
    },
    Search() {
      this.$router.push({ path: "/search", query: { q: this.searchText } });
    },
  },
};
