<template>
  <div id="app" style="margin-top: 0px; padding-top: 0px;">
    <router-view></router-view>
    <Modal></Modal>
  </div>
</template>

<script>
import Modal from '@/components/modal/modal';

  export default {
    name: 'App',
    components: {
      Modal
    }
  }
</script>
<style>
  @import './assets/libs/bootstrap-5.0.2-dist/css/bootstrap.min.css';
  @import './assets/libs/aos.css';
  @import './assets/styles/stylesheet.css';
  @import './assets/libs/fontawesome/css/all.css';
  
</style>
<!--
-->
<style lang="scss">
  @import './assets/styles/style.scss';
  @import './assets/libs/fontawesome/scss/fontawesome.scss';
</style>